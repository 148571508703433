<template>
    <!--begin::Modal - New Card-->
    <!--waste type model in companies-->
    <button type="button" class="btn btn-primary mx-3" data-bs-toggle="modal" data-bs-target="#kpi_address">
        KPI Address Details
    </button>
    <div class="modal fade" ref="submitButtonRef" id="kpi_address" tabindex="-1" aria-hidden="true">
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px roundeda">
            <!--begin::Modal content-->
            <div class="modal-content roundeda">
                <!--begin::Modal header-->
                <div class="modal-header bg-primary">
                    <!--begin::Modal title-->
                    <span class="text-white text-center ms-3 fs-5">Address Details</span>
                    <!-- <button type="button" class="btn btn-primary" @click="toggle = !toggle">
                        + Add Address
                    </button> -->
                    <!--end::Modal title-->
                    <!--begin::Close-->
                    <div class="display_none btn btn-sm btn-icon btn-active-color-dark bg-white rounded-circle"
                        data-bs-dismiss="modal" id="addnewmodalclose">
                        <span class="svg-icon svg-icon-1 text-primary fs-6"> X </span>
                    </div>
                    <!--end::Close-->
                </div>
                <!--end::Modal header-->

                <div class="card-body pt-0 mt-4" style="width: 100%">
                    <div>
                        <b-form-select v-model="assign_value.company_id" class="w-50 float-end mb-4 mt-5" v-if="!toggle"
                            @change="address_details($event)">
                            <b-form-select-option :value="null">Please Select Company</b-form-select-option>
                            <option v-for="(option, idx) in customer_list" :key="idx" :value="option.id"
                                :title="option.name">
                                {{ `${option.name}(${option.id})` }}
                            </option>
                        </b-form-select>
                    </div>
                    <b-table-simple v-if="!toggle" bordered outlined noCollapse> 
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th>Groups</b-th>
                                <b-th>Address</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <template v-for="group in processedData" :key="group.group_id">
                                <b-tr v-for="(address, index) in group.addresses" :key="address.address_id">
                                    <template v-if="index === 0">
                                        <b-td :rowspan="group.rowspan" class="vertical-center">{{ group.group_name }}</b-td>
                                    </template>
                                    <b-td>{{ address.address_name }}</b-td>
                                </b-tr>
                                <!-- If there are no addresses, still display the group name with an empty row -->
                                <b-tr v-if="group.addresses.length === 0">
                                    <b-td class="vertical-center">{{ group.group_name }}</b-td>
                                    <b-td></b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                        <!-- Address Detail Table -->
                    </b-table-simple>
                </div>
                <!--begin::Modal body-->

                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>

    <!--end::Modal - New Card-->
</template>
<script>
import { defineComponent, onMounted, ref } from "@vue/runtime-core";
import {
    getCustomersList,
    AddressDetails
} from "@/store/api/devices";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
    name: "editpermission",
    setup() {
        const items = ref([]);
        const waste_type_id = ref("");
        const searchValue = ref();
        const loading = ref(false);
        const saveloading = ref(false);
        const toggle = ref(false);
        const customer_list = ref([]);
        const processedData = ref([]);
        const payload = ref({ name: "", icon: "" });
        const assign_value = ref({
            company_id: "",
            waste_type_ids: [],
        });
        onMounted(async () => {
            items.value.map((item) => ({ ...item, isEdit: false }));
            const feature = await getCustomersList();
            customer_list.value = feature;
        });

        const address_details = async (event) => {
            loading.value = true;
            const response = await AddressDetails(event);
            console.log("Response Address", response);
            processedData.value = response.map(group => {
                return {
                    ...group,
                    rowspan: group.addresses.length || 1
                };
            });
            loading.value = false;
        };

        return {
            items,
            customer_list,
            waste_type_id,
            loading,
            saveloading,
            assign_value,
            payload,
            searchValue,
            toggle,
            address_details,
            processedData
        };
    },
});
</script>
<style scoped>
.btn.btn-secondary.permission-action {
    padding: 3px !important;
    background: #fff !important;
}

.btn.btn-secondary.permission-action:hover {
    background-color: #fff !important;
    background: #fff !important;
}

.btn.btn-secondary.permission-action:focus {
    background-color: #fff !important;
    background: #fff !important;
}

.badge-light-success.modal-badge {
    padding-top: 4px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
    padding-right: 4px !important;
}

/* .table td:last-child {
   display: flex !important;
  } */
.display_none {
    display: none;
}

.display_block {
    display: block;
}

.label {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    transition: all 100ms ease-in;
    filter: brightness(0.9) grayscale(1.7) opacity(0.9) !important;
}

.float-end {
    border-color: #009ef7;
}

.vertical-center {
    vertical-align: middle;
}
</style>